var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "picWrapper" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "viewer",
            rawName: "v-viewer",
            value: { movable: false },
            expression: "{movable: false}",
          },
        ],
        staticClass: "picbox",
        style: _vm.styleObj,
      },
      _vm._l(_vm.pics, function (val, ind) {
        return _c("div", { key: val, staticClass: "picItem" }, [
          _c("img", {
            style: { width: "300px", height: "200px" },
            attrs: { src: val },
          }),
          _c("div", { staticClass: "word" }, [
            _vm._v("第" + _vm._s(ind + 1) + "次"),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }