<template>
  <div class='picWrapper'>
    <div class='picbox' v-viewer="{movable: false}" :style="styleObj">
      <div v-for='(val, ind) in pics' :key="val" class='picItem'>
        <img :src='val' :style='{width:"300px",height:"200px"}'/>
        <div class='word'>第{{ind + 1}}次</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['pics'],
  data() {
    return {
      styleObj: {
        width: '100%'
      }
    }
  },
  beforeUpdate() {
    this.setStyle()
  },
  methods: {
    setStyle() {
      this.styleObj = {
        width: this.pics.length * (300 + 20) + 'px',
        height: '250px'
      }
    }
  },
  created() {
    this.setStyle()
  }
}
</script>
<style scoped>
  .picWrapper{
    overflow: auto;
    width: 100%;
    height: 255px;
  }
  .picbox{
    overflow: hidden;
  }
  .picItem{
    padding: 10px;
    float: left;
  }
  .word{
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
</style>

